<template>
  <div class="home" id="home" name="home">
    <!-- 轮播图 -->
    <div>
      <div class="block">
        <el-carousel :height="carouselHeight">
          <el-carousel-item v-for="item in carouselList" :key="item.id">
            <router-link :to=item.router>
              <img style="width: 100%; object-fit: cover;" :src="item.imgUrl" />
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 轮播图END -->
      <div class="wrapper">
        <div class="brand">
          <div v-for="item in brandList" :key="item.id">
            <div>
              <router-link :to=item.link><img style="width:303px; height: 121px;" :src="item.imgPath"></router-link>
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: #F5F5F5;padding-top: 41px; height: 337px;">
        <div style="width: 970px;margin: 0 auto;">
          <div style="display: flex;justify-content: center;">
            <div style="font-size: 36px;font-weight: bold;">Our Brands</div>
          </div>
          <div style="margin: 25px 0;">
            <div style="display: flex;justify-content: center;">Over the decades, we have been dedicated to a single goal:
              creating entirely new life experiences for pet
              and their parents. </div>
            <div style="display: flex;justify-content: center;">
              <p>Our brands emphasizsed innovative, safe, and enjoyable designs, and we continuously create our products
                with our </p>
            </div>
            <div style="display: flex;justify-content: center;">
              <p>perspective, missions and values, driving forward the industry and benefiting society.</p>
            </div>
          </div>
          <div style="display: flex;justify-content: center;">
            <div class="brands">
              <router-link to="/brands">
                <p style="color: #FFF;font-weight: bold;cursor: pointer;">Explore Our Brands</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div style="display: flex;justify-content: center;margin-top: 130px;">
          <div style="font-size: 36px;font-weight: bold;">Lastest Video</div>
        </div>
        <div style="margin-top: 57px;display: flex;justify-content: center;">
          <div>
            <video muted="muted" :src="video.imgUrl" type="video/mp4" controls="controls" loop
              style="width: 790px;height: 455px;">
            </video>
          </div>
        </div>

        <div v-if="!this.$store.getters.getUser">
          <div style="display: flex;justify-content: center;margin: 102px 0 60px">
            <div style=" font-size: 36px;font-weight: bold;">Shop by Range</div>
          </div>
          <div><img src="../assets/imgs/uploads/Group 339.png" alt="" style="width: 100%;height: 249px;"></div>
          <div style="margin: 70px 0 140px;display: flex;justify-content: space-between;">
            <div v-for="item in dogs" :key="item.id">
              <div class="tg">dog</div>
              <router-link :to="{ path: '/details', query: { spuId: item.spuId } }">
                <img v-lazy="item.imgUrl" alt="" style="width: 311px;height: 273px;">
              </router-link>
              <div style="margin-top: 14px;font-size: 17px;display: flex;justify-content: center;">
                <p style="font-weight: bold;text-align: center;">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div><img src="../assets/imgs/uploads/Group 319.png" alt="" style="width: 100%;height: 249px;"></div>
          <div style="margin-top: 70px;display: flex;justify-content: space-between;">
            <div v-for="item in cats" :key="item.id">
              <div class="tg">cat</div>
              <router-link :to="{ path: '/details', query: { spuId: item.spuId } }">
                <img v-lazy="item.imgUrl" alt="" style="width: 311px;height: 273px;">
              </router-link>
              <div style="margin-top: 14px;font-size: 17px;display: flex;justify-content: center;">
                <p style="font-weight: bold;text-align: center;">{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="display: flex;justify-content: center;margin: 130px 0 60px">
            <div style="font-size: 36px;font-weight: bold;">Best Sellers</div>
          </div>
          <div style="margin: 70px 0 79px;cursor: pointer;">
            <!-- <button @click="scrollLeft" :disabled="disableLeftArrow || isThrottled">＜</button> -->
            <div class="product-slider">
              <div @click="scrollLeft" class="leftarrow" :class="(disableLeftArrow || isThrottled) ? 'isable' : ''">
                <img src="../assets/imgs/uploads/Group 681.png" style="width: 29px;height: 29px;" alt="">
              </div>
              <div @click="scrollRight" class="rightarrow" :class="(disableRightArrow || isThrottled) ? 'isable' : ''">
                <img src="../assets/imgs/uploads/Group 680.png" style="width: 29px;height: 29px;" alt="">
              </div>
              <div class="product-window">
                <div class="product-container" :style="containerStyle">
                  <div v-for="item in bestSellers" :key="item.id">
                    <div :class="item.categoryName == 'DOG' ? 'tg' : 'tt'">{{ item.categoryName }}</div>
                    <router-link :to="{ path: '/details', query: { spuId: item.spuId } }">
                      <img v-lazy="item.imgUrl" alt="" style="width: 300px;height: 273px;margin: 0 10px;">
                    </router-link>
                    <div style="margin-top: 14px;font-size: 17px;display: flex;justify-content: center;">
                      <p style="font-weight: bold;">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex;justify-content: center;margin-bottom: 62px;">
            <div style="font-size: 36px;font-weight: bold;">What’s New</div>
          </div>
          <div class="campaign">
            <img src="../assets/imgs/uploads/campaign.jpg" alt=""
              style="width: 1280px;height: 292px;border-radius: 20px;">
          </div>
          <div>
            <div class="product-slider1">
              <div @click="scrollLeft1" class="leftarrow1" :class="(disableLeftArrow1 || isThrottled1) ? 'isable' : ''">
                <img src="../assets/imgs/uploads/Group 681.png" style="width: 29px;height: 29px;" alt="">
              </div>
              <div @click="scrollRight1" class="rightarrow1"
                :class="(disableRightArrow1 || isThrottled1) ? 'isable' : ''">
                <img src="../assets/imgs/uploads/Group 680.png" style="width: 29px;height: 29px;" alt="">
              </div>
              <div class="product-window">
                <div class="product-container" :style="containerStyle1">
                  <div v-for="(item, index) in whatsNews" :key="index">
                    <div :class="item.categoryName == 'DOG' ? 'tg' : 'tt'">{{ item.categoryName }}</div>
                    <router-link :to="{ path: '/details', query: { spuId: item.spuId } }">
                      <img v-lazy="item.imgUrl" alt="" style="width: 300px;height: 273px;margin: 0 10px;">
                    </router-link>
                    <div style="margin-top: 14px;font-size: 17px;display: flex;justify-content: center;">
                      <p style="font-weight: bold;">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: center;margin: 159px 0 60px;">
            <div style="font-size: 36px;font-weight: bold;">Shop by Catalog</div>
          </div>
          <div class="image-container">
            <div class="imghandle" style="overflow: hidden;border-radius: 20px;">
              <img @click="handleImageClick('left')" :src="leftImageSrc" alt="Left Image"
                style="width: 630px;height: 250px;">
            </div>
            <div class="imghandle" style="overflow: hidden;border-radius: 20px;">
              <img @click="handleImageClick('right')" :src="rightImageSrc" alt="Right Image"
                style="width: 630px;height: 250px;">
            </div>
          </div>

          <div v-if="selectedImage === 'left'" style="display: flex; justify-content: space-between;">
            <div v-for="item in dogcategory" :key="item.id">
              <router-link to="/goods">
                <div style="position: relative;">
                  <div class="bigg">
                    <img :src="item.imgPath" alt=""
                      style="width: 210px;height: 210px;border-radius: 20px;transition: all 0.6s;">
                  </div>
                  <div class="label">
                    <div style="max-width: 150px;font-size: 20px;">{{ item.label }}</div>
                    <div style="max-width: 150px;font-size: 20px;">{{ item.label1 }}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else style="display: flex; justify-content: space-between;">
            <div v-for="item in category" :key="item.id">
              <router-link to="/goods">
                <div style="position: relative;">
                  <div class="bigg">
                    <img :src="item.imgPath" alt=""
                      style="width: 210px;height: 210px;border-radius: 20px;transition: all 0.6s;">
                  </div>
                  <div class="label">
                    <div style="max-width: 150px;font-size: 20px;">{{ item.label }}</div>
                    <div style="max-width: 150px;font-size: 20px;">{{ item.label1 }}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div style="display: flex;justify-content: center;margin-top: 88px;">
          <div style=" font-size: 36px;font-weight: bold;">We Care Our Wholesaler</div>
        </div>
        <div style="margin: 60px 0 83px;display: flex;justify-content: space-between;">
          <button v-for="(button, index) in buttons" :key="button.id" :class="{ 'active': activeButton === index }"
            @click="changeActiveButton(index)" class="btn">
            <span style="font-size: 14px;font-weight: bold;">{{ button.text }}</span>
          </button>
        </div>
        <div class="wholesaler">
          <el-carousel :interval="5000" indicator-position="none" arrow="always" @change="carouselChange">
            <el-carousel-item v-for="index in 4" :key="index">
              <div style="display: flex;align-items: center;">
                <img :src="itemdetail.imageUrl" alt="Button Image" class="rounded-image" />
                <div style="margin-left: 63px;width: 466px;">
                  <p style="font-size: 20px;font-weight: bold;margin-bottom: 20px;">{{ itemdetail.text }}</p>
                  <div style="font-size: 16px;">{{ itemdetail.description }}</div>
                </div>
              </div>
            </el-carousel-item>
            <div>
            </div>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      selectedImage: 'left', // 默认选中左边图片
      leftImageSrc: require('../assets/imgs/uploads/Group 3181.png'),
      rightImageSrc: require('../assets/imgs/uploads/Group 31.png'),
      skuId: "", // 商品id
      productDetail: "", // 商品详细信息
      carouselList: [],
      startIndex: 0,
      isThrottled: false,
      startIndex1: 0,
      isThrottled1: false,
      carouselHeight: '714px',
      brandList: [
        { id: 1, imgPath: require('../assets/imgs/uploads/brand2.png'), link: '/exploregigwi' },
        { id: 2, imgPath: require('../assets/imgs/uploads/brand1.png'), link: '/exploregp' },
        { id: 3, imgPath: require('../assets/imgs/uploads/brand3.png'), link: '/explorepetgeek' },
        { id: 4, imgPath: require('../assets/imgs/uploads/brand4.png'), link: '/exploreskipdawg' }
      ],
      dogs: [],
      bestSellers: [],
      whatsNews: [],
      cats: [],
      dogcategory: [
        {
          id: 1,
          imgPath: require('../assets/imgs/uploads/2.toy.jpg'),
          label: 'Toys',
          label1: ''
        },
        {
          id: 2,
          imgPath: require('../assets/imgs/uploads/3Collars,Harnesses.jpg'),
          label: 'Accessories',
          label1: ''
        },
        {
          id: 3,
          imgPath: require('../assets/imgs/uploads/4.Feeding & Watering Supplies.jpg'),
          label: 'Supplies',
          label1: ''
        },
        {
          id: 4,
          imgPath: require('../assets/imgs/uploads/5.Beds&pats.jpg'),
          label: 'Beddings',
          label1: '',
        },
        {
          id: 5,
          imgPath: require('../assets/imgs/uploads/1.Grooming.jpg'),
          label: 'Grooming',
          label1: ''
        },
      ],
      category: [
        { id: 1, imgPath: require('../assets/imgs/uploads/cats2.jpg'), label: 'Toys' },
        {
          id: 2,
          imgPath: require('../assets/imgs/uploads/cats3.jpg'),
          label: 'Accessories',
          label1: '',
        },
        { id: 3, imgPath: require('../assets/imgs/uploads/cats4.jpg'), label: 'Supplies', label1: '' },
        {
          id: 4,
          imgPath: require('../assets/imgs/uploads/cats5.jpg'),
          label: 'Beddings',
          label1: '',
        },
        { id: 5, imgPath: require('../assets/imgs/uploads/cats1.jpg'), label: 'Grooming', label1: '' },
      ],
      buttons: [
        {
          id: 1,
          text: 'Our Platform',
          imageUrl: require('../assets/imgs/uploads/onestop.png'),
          description: 'We provide our customers with a one-stop procurement platform, with the most cost-effective products.'
        },
        {
          id: 2,
          text: 'Logistics Services',
          imageUrl: require('../assets/imgs/uploads/logistics.png'),
          description: 'Providing the whole logistic service,no matter container or package.You can choose FOB or CIF freely.We also can deal with all products of import and export ,send goods to your home.'
        },
        {
          id: 3,
          text: 'Marketing Promotion',
          imageUrl: require('../assets/imgs/uploads/market.png'),
          description: "Providing different types of promotional files.Pictures,videos,product's specifications,You can use these it for posting in store,exhibition.Or  upload for online shops."
        },
        {
          id: 4,
          text: 'Upgrade Your Business Experience',
          imageUrl: require('../assets/imgs/uploads/upgrade.png'),
          description: 'We start our business in the pet sector science 1998.Servicing for more than 30 countries more than 25 years.We have enough experience to help our customers.No matter which stage you stand,we are willing to help you start and grow up.'
        },
      ],
      itemdetail: {},
      activeButton: 0,
      video: [],
    }
  },
  created() {
    this.itemdetail = this.buttons[0];
    this.getBestSellers()
    this.getNews()
    this.getdogs()
    this.getcats()
  },
  activated() {
    if (this.$route.query.skuId != undefined) {
      this.skuId = this.$route.query.skuId;
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      window, scrollTo(0, 0)
    })
    this.getCarousel()
    this.updateCarouselHeight();
    window.addEventListener('resize', this.updateCarouselHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCarouselHeight);
  },
  computed: {
    containerStyle() {
      // offset 应该是 startIndex 乘以单个商品的宽度百分比
      const offset = -(this.startIndex * 25); // 因为每个商品宽度占25%
      return {
        transform: `translateX(${offset}%)`,
        transition: 'transform 0.5s ease'
      };
    },
    containerStyle1() {
      // offset 应该是 startIndex 乘以单个商品的宽度百分比
      const offset = -(this.startIndex1 * 25); // 因为每个商品宽度占25%
      return {
        transform: `translateX(${offset}%)`,
        transition: 'transform 0.5s ease'
      };
    },
    // 检查是否到达了最左侧
    disableLeftArrow() {
      return this.startIndex === 0;
    },
    // 检查是否到达了最右侧
    disableRightArrow() {
      return this.startIndex >= this.bestSellers.length - 4;
    },
    disableLeftArrow1() {
      return this.startIndex1 === 0;
    },
    disableRightArrow1() {
      return this.startIndex1 >= this.whatsNews.length - 4;
    }
  },
  methods: {
    ...mapActions(["unshiftShoppingCart", "addShoppingCartNum"]),
    // 获取商品详细信息
    carouselChange: function (key1) {
      this.activeButton = key1
      this.itemdetail = this.buttons[key1];
    },
    changeActiveButton(index) {
      this.activeButton = index;
      this.itemdetail = this.buttons[index];
    },
    getBestSellers() {
      this.$axios
        .post("/api/mall/home/getBestSellers")
        .then(res => {
          if (res.data.code === 200) {
            this.bestSellers = res.data.data
          } else {
            this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    getdogs() {
      this.$axios
        .post("/api/mall/home/getDogList")
        .then(res => {
          if (res.data.code === 200) {
            this.dogs = res.data.data
          } else {
            this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    getcats() {
      this.$axios
        .post("/api/mall/home/getCatList")
        .then(res => {
          if (res.data.code === 200) {
            this.cats = res.data.data
          } else {
            this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    getNews() {
      this.$axios
        .post("/api/mall/home/getNew")
        .then(res => {
          if (res.data.code === 200) {
            this.whatsNews = res.data.data
          } else {
            this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    handleImageClick(side) {
      if (side === 'left') {
        this.leftImageSrc = require('../assets/imgs/uploads/Group 3181.png');
        this.rightImageSrc = require('../assets/imgs/uploads/Group 31.png');
      } else {
        this.leftImageSrc = require('../assets/imgs/uploads/Group 318.png');
        this.rightImageSrc = require('../assets/imgs/uploads/Group 311.png');
      }
      this.selectedImage = side;
    },
    getCarousel() {
      this.$axios
        .post("/api/mall/home/getData")
        .then(res => {
          if (res.data.code === 200) {
            this.carouselList = res.data.data.image
            this.video = res.data.data.video[0]
          } else {
            this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    scrollLeft() {
      if (this.disableLeftArrow) return;
      this.throttle();
      this.startIndex -= 1;
    },
    scrollRight() {
      if (this.disableRightArrow) return;
      this.throttle();
      this.startIndex += 1;
    },
    throttle() {
      if (this.isThrottled) return;
      this.isThrottled = true;
      setTimeout(() => (this.isThrottled = false), 500);
    },
    scrollLeft1() {
      if (this.disableLeftArrow1) return;
      this.throttle1();
      this.startIndex1 -= 1;
    },
    scrollRight1() {
      if (this.disableRightArrow1) return;
      this.throttle1();
      this.startIndex1 += 1;
    },
    throttle1() {
      if (this.isThrottled1) return;
      this.isThrottled1 = true;
      setTimeout(() => (this.isThrottled1 = false), 500);
    },
    updateCarouselHeight() {
      if (window.innerWidth < 1268) {
        this.carouselHeight = '472px';
      } else if (window.innerWidth < 1600) {
        this.carouselHeight = '550px';
      } else if (window.innerWidth < 1920) {
        this.carouselHeight = '600px';
      } else if (window.innerWidth > 2100 && window.innerWidth < 2300) {
        this.carouselHeight = '786px';
      } else if (window.innerWidth > 2300) {
        this.carouselHeight = '885px';
      } else {
        this.carouselHeight = '714px';
      }
    }
  }
};
</script>
<style scoped>
/* 轮播图CSS */
.product-slider {
  width: 1280px;
  overflow: hidden;
  position: relative;
}

.product-slider1 {
  cursor: pointer;
  width: 1280px;
  overflow: hidden;
  position: relative;
}

.product-slider:hover .leftarrow {
  display: block;
}

.product-slider:hover .rightarrow {
  display: block;
}

.leftarrow {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.rightarrow {
  display: none;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  z-index: 5;
}

.product-slider1:hover .leftarrow1 {
  display: block;
}

.product-slider1:hover .rightarrow1 {
  display: block;
}

.leftarrow1 {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.rightarrow1 {
  display: none;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  z-index: 5;
}

.product-window {
  overflow: hidden;
}

.product-container {
  display: flex;
  /* 提升性能 */
}

.isable {
  pointer-events: none;
  display: none !important;
}

.block {
  width: 100%;
}

/* 轮播图CSS END */
.wrapper {
  width: 1280px;
  margin: 0 auto;
}

.wholesaler {
  margin-bottom: 64px;
}

::v-deep .el-carousel__arrow {
  z-index: 5;
}

::v-deep .el-carousel__arrow--left {
  width: 30px;
  height: 30px;
  font-size: 20px;
}

::v-deep .el-carousel__arrow--left:hover {
  background-color: #d86568;
}

::v-deep .el-carousel__arrow--right {
  width: 30px;
  height: 30px;
  font-size: 20px;
}

::v-deep .el-carousel__arrow--right:hover {
  background-color: #d86568;
}

::v-deep .wholesaler .el-carousel__arrow {
  width: 40px;
  height: 40px;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
}

/* 子盒子，设置为： */

.hover-image {
  display: none;
}


.el-main {
  margin: 0 !important;
}

.brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 0;
}

.vpi {
  height: 380px;
  width: 230px;
  padding: 10px;
  border: 3px solid #808080;
  border-radius: 30px;
}

.vpi:hover {
  border: 3px solid #d86568;
}

.custom-divider {
  border: 2px solid #f09285;
  /* 设置粗细和颜色 */
}

.btn {
  width: 314px;
  height: 64px;
  margin: 0;
  border: none;
  border-radius: 15px;
  background-color: #EAEAEA;
  display: block;
}

.brands {
  width: 236px;
  height: 53px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.active {
  background-color: #333;
  color: #fff;
}

.area {
  display: flex;
  align-items: center;
  margin-top: 83px;
  width: 1140px;
  height: 348px;
}

.image {
  flex: 1;
}

.text {
  flex: 2;
  padding-left: 83px;
}

.image img {
  width: 591px;
  height: 348px;
}

.tg {
  font-size: 9px;
  font-weight: bold;
  width: 36px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  background-color: #C6A4FF;
  color: #fff;
  border-radius: 38px;
}

.tt {
  font-size: 9px;
  font-weight: bold;
  width: 36px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  background-color: #95C0FF;
  color: #fff;
  border-radius: 38px;
}

.campaign {
  height: 292px;
  margin-bottom: 90px;
}

.image-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 63px;
}

.imghandle:hover img {
  transform: scale(1.1);
  transition: all .5s linear !important;
}

.label {
  position: absolute;
  bottom: 7%;
  left: 50%;
  color: #FFF;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateX(-50%);
}

.bigg {
  border-radius: 20px;
  overflow: hidden;
}

.bigg:hover img {
  transform: scale(1.3)
}

::v-deep .el-carousel__container {
  height: 348px;
}

.rounded-image {
  width: 591px;
  height: 348px;
  margin-left: 100px;
  object-fit: cover;
}
</style>